.filemanager-header {
  .header-button {
    height: 2rem;
    width: 2rem;
    font-size: 0;
    border: 0;
    margin-left: .5rem;
    opacity: .4;
    vertical-align: middle;
    padding: 6px 10px;
    margin-right: 5px;
    font-family: inherit;
  }
  .input-button-grid {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Z3JpZC1sYXlvdXQ8L3RpdGxlPjxwYXRoIGQ9Ik0zIDIuNjcxYzAgLjIyLS4xMS4zMjktLjMyOS4zMjlILjMzQy4xMDkgMyAwIDIuODkgMCAyLjY3MVYuMzNDMCAuMTA5LjExIDAgLjMyOCAwaDIuMzQzQzIuODkxIDAgMyAuMTEgMyAuMzI4djIuMzQzem01Ljk4OCAwYzAgLjIyLS4xMS4zMjktLjMyOC4zMjlINi4zMTVjLS4yMTggMC0uMzI3LS4xMS0uMzI3LS4zMjlWLjMzYzAtLjIyLjEwOS0uMzI5LjMyNy0uMzI5SDguNjZjLjIxOSAwIC4zMjcuMTEuMzI3LjMyOHYyLjM0M2guMDAxem01Ljk4NyAwYzAgLjIyLS4xMS4zMjktLjMyOC4zMjloLTIuMzQ0Yy0uMjIgMC0uMzI5LS4xMS0uMzI5LS4zMjlWLjMzYzAtLjIyLjExLS4zMjkuMzMtLjMyOWgyLjM0MmMuMjIgMCAuMzI5LjExLjMyOS4zMjh2Mi4zNDN6TTMgOC42NjFjMCAuMjE4LS4xMS4zMjctLjMyOS4zMjdILjMzYy0uMjIgMC0uMzI5LS4xMDktLjMyOS0uMzI3VjYuMzE1YzAtLjIxOC4xMS0uMzI3LjMyOC0uMzI3aDIuMzQzYy4yMiAwIC4zMjkuMTA5LjMyOS4zMjd2Mi4zNDZ6bTUuOTg4IDBjMCAuMjE4LS4xMS4zMjctLjMyOC4zMjdINi4zMTVjLS4yMTggMC0uMzI3LS4xMDktLjMyNy0uMzI3VjYuMzE1YzAtLjIxOC4xMDktLjMyNy4zMjctLjMyN0g4LjY2Yy4yMTkgMCAuMzI3LjEwOS4zMjcuMzI3djIuMzQ2aC4wMDF6bTUuOTg3IDBjMCAuMjE4LS4xMS4zMjctLjMyOC4zMjdoLTIuMzQ0Yy0uMjIgMC0uMzI5LS4xMDktLjMyOS0uMzI3VjYuMzE1YzAtLjIxOC4xMS0uMzI3LjMzLS4zMjdoMi4zNDJjLjIyIDAgLjMyOS4xMDkuMzI5LjMyN3YyLjM0NnpNMyAxNC42NDdjMCAuMjE4LS4xMS4zMjgtLjMyOS4zMjhILjMzYy0uMjIgMC0uMzI5LS4xMS0uMzI5LS4zMjh2LTIuMzQ0YzAtLjIyLjExLS4zMjkuMzI4LS4zMjloMi4zNDNjLjIyIDAgLjMyOS4xMS4zMjkuMzN2Mi4zNDJ6bTUuOTg4IDBjMCAuMjE4LS4xMS4zMjgtLjMyOC4zMjhINi4zMTVjLS4yMTggMC0uMzI3LS4xMS0uMzI3LS4zMjh2LTIuMzQ0YzAtLjIyLjEwOS0uMzI5LjMyNy0uMzI5SDguNjZjLjIxOSAwIC4zMjcuMTEuMzI3LjMzdjIuMzQyaC4wMDF6bTUuOTg3IDBjMCAuMjE4LS4xMS4zMjgtLjMyOC4zMjhoLTIuMzQ0Yy0uMjIgMC0uMzI5LS4xMS0uMzI5LS4zMjh2LTIuMzQ0YzAtLjIyLjExLS4zMjkuMzMtLjMyOWgyLjM0MmMuMjIgMCAuMzI5LjExLjMyOS4zM3YyLjM0MnoiIGZpbGw9IiMzOTNENDYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==") center center no-repeat;
    background-size: 20px 15px;
  }
  .input-button-list {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+R3JvdXA8L3RpdGxlPjxnIGZpbGw9IiMzOTNENDYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTAgMEgyMFYySDB6Ii8+PHBhdGggZD0iTTAgNUgyMFY3SDB6Ii8+PHBhdGggZD0iTTAgMTBIMjBWMTJIMHoiLz48L2c+PC9zdmc+") center center no-repeat;
    background-size: 20px 15px;
  }
}