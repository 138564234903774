.input-group-navbar .btn,
.input-group-search .btn,
.input-group-navbar .form-control,
.input-group-search .form-control{
  background:#f3f6fa;
  border:0;
  border-radius:20px;
  box-shadow:none;
  height:calc(2.1875rem + 2px);
  padding:.4rem 1rem
}
.input-group-navbar .btn:focus,
.input-group-navbar .form-control:focus {
  background:#f3f6fa;
  box-shadow:none;
  outline:0
}
.input-group-navbar .btn {
  color:#6c757d
}
.input-group-navbar .btn .feather {
  height:20px;
  width:20px
}
